 .betweenmargin{
    @media only screen and (max-width: 331px) and (min-width: 237px)  {
       height: 210px !important ;
    }
    @media only screen and (max-width: 515px) and (min-width: 331px)  {
        height: 402px !important ; 
    }
}
.bottommargin{
    @media only screen and (max-width: 639px) and (min-width: 376px)  {
         margin-bottom: 600px; 
    }
}

.nft_clip {
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%, 0 10%);
    // position: relative;
    // .cut_left_corner_nft {
    //     position: absolute;
    //     top: 0px;
    //     left: 0px;
    //     height: 100px;
    //     width: 1px;
    //     transform: rotate(45deg);
    //     background-color: gold;
    //     // clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);        
    // }
}
