// @media screen and (min-width: 640px) {
//     .outsidecard {
//         position: relative;
//         background: #e5bf00;
//         clip-path: polygon(11% 0, 100% 0, 100% 20%, 100% 100%, 80% 100%, 20% 100%, 0 100%, 0 8%);
//     }
// }
// .insidecard {
//     position: absolute;
//     top: 1px;
//     left: 1px;
//     right: 1px;
//     bottom: 1px;
//     background: black;
//     clip-path: polygon(11% 0, 100% 0, 100% 20%, 100% 100%, 80% 100%, 20% 100%, 0 100%, 0 8%);
// }

.teams-card{
    border: 1px solid gold;
    min-height: 500px;
    max-width: 370px;
    margin-bottom: 44px;
   .profile{
        bottom: -40px;
    }
}
.content_card_team {
    position: relative;
    width:100%;
    @media screen and (max-width: 336px) {
      // width: 90%;
      height: 600px;
    }
    @media screen and (min-width:337) and (max-width: 639px) {
      // width: 90%;
      height: 550px;
    }
    @media screen and (min-width: 640px) {
      // width: 90%;
      height: 500px;
    }
  
    .top-left-angle {
      position: absolute;
      top: -3px;
      left: -3px;
      border-top: 14px solid black;
      border-right: 30px solid transparent;
      border-bottom: 34px solid transparent;
      border-left: 20px solid black;
  
      &::after {
        position: absolute;
        transition: all 0.5s;
        content: "";
        height: 60px;
        border-left: 1px solid gold;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-top: 1px solid gold;
        transform: rotate(45deg);
        top: -20px;
        left: 3px;
      }
    }
  
   
  }