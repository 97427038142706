.content_card_terms_conditions {
    position: relative;
    padding: 28px;
    border: 1px solid #362B15;

    .top-left-angle {
        position: absolute;
        top: -4px;
        left: -4px;
        border-top: 24px solid black;
        border-right: 24px solid transparent;
        border-bottom: 24px solid transparent;
        border-left: 24px solid black;

        &::after {
            position: absolute;
            // transition: all 0.5s ease;
            content: "";
            height: 62px;
            border-left: 1px solid #362B15;
            border-right: 1px solid transparent;
            border-bottom: 1px solid transparent;
            border-top: 1px solid #362B15;
            transform: rotate(45deg);
            top: -30px;
            left: 0px;
        }
    }
    
}


.content_card_terms_conditions:hover {
    border: 1px solid #FFCA65;

    .top-left-angle{
        &::after{
            border-left: 1px solid #FFCA65;
            border-top: 1px solid #FFCA65;
        }

    }
}
