.content_card_yellow {
  position: relative;

  .top-left-angle {
    position: absolute;
    top: -4px;
    left: -4px;
    border-top: 24px solid black;
    border-right: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 24px solid black;

    &::after {
        position: absolute;
        transition: all 0.5s;
        content: "";
        height: 62px;
        border-left: 1px solid gold;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-top: 1px solid gold;
        transform: rotate(45deg);
        top: -30px;
        left: 0px;
    }
}
}

%test {
  position: absolute;
  content: "";
  transition: all 0.5s;
}
.content_card_yellow_div {
  // display: inline-block;
  // padding: 20px 40px;
  @media screen and (max-width: 639px) {
    width:60%;
  }
  @media screen and (min-width: 640px) {
    width: 67%;
  }
  color: white;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  transform: translate(-0.1%, -0.1%);
  vertical-align: middle;
  font-family: "Strasua";
  text-decoration: none;
  //   font-size: 4vw;
  transition: all 0.5s;
  //   background-color: #3498db;
  border: 1px solid gold;
  &::before {
      @extend %test;
      // bottom: -15px;
      // height: 15px;
      // width: 100%;
      // left: 8px;
      // transform: skewX(45deg);
      // border: 1px solid gold;

      bottom: -9px;
      height: 9px;
      width: 100.3%;
      left: 4px;
      transform: skewX(50deg);
      border: 1px solid gold;
      background-color: black;
      border-top: 0px;
      border-right: 0px;
  }

  &::after {
      @extend %test;
     /*  right: -10px;
      height: 100%;
      width: 10px;
      bottom: -8px;
      transform: skewY(45deg);
      border: 1px solid gold; */
      right: -12px;
  height: 100%;
  width: 13px;
  bottom: -4px;
  transform: skewY(38deg);
  border: 1px solid gold;
  background-color: black;
  border-left: 0px;
  }

  
}

.content_card_footer {
  position: relative;
  width:90%;
  @media screen and (max-width: 336px) {
    // width: 90%;
    height: 600px;
  }
  @media screen and (min-width:337) and (max-width: 639px) {
    // width: 90%;
    height: 550px;
  }
  @media screen and (min-width: 640px) {
    // width: 90%;
    height: 500px;
  }

  .top-left-angle {
    position: absolute;
    top: -3px;
    left: -3px;
    border-top: 14px solid black;
    border-right: 30px solid transparent;
    border-bottom: 34px solid transparent;
    border-left: 20px solid black;

    &::after {
      position: absolute;
      transition: all 0.5s;
      content: "";
      height: 60px;
      border-left: 1px solid gold;
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
      border-top: 1px solid gold;
      transform: rotate(45deg);
      top: -20px;
      left: 3px;
    }
  }

  .top-right-angle {
    position: absolute;
    top: -2px;
    right: -2px;
    border-top: 40px solid black;
    border-right: 23px solid black;
    border-bottom: 2px solid transparent;
    border-left: 19px solid transparent;

    &::after {
      position: absolute;
      transition: all 0.5s;
      content: "";
      height: 60px;
      border-left: 1px solid transparent;
      border-right: 1px solid gold;
      border-bottom: 1px solid transparent;
      border-top: 1px solid transparent;
      transform: rotate(-45deg);
      bottom: -12px;
    }


  }
}