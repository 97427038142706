h1 {
    text-align: center;
    font-weight: 300;
    color: #777;
}

h1 span {
    font-weight: 600;
}

.container_rd {
    width: 80%;
    padding: 50px 0;
    margin: 50px auto;
    position: relative;
    overflow: hidden;
}

.container_rd:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 100%;
    background: #e5bf00;
    z-index: 1;
}

.timeline-block {
    width: -webkit-calc(50% + 8px);
    width: -moz-calc(50% + 8px);
    width: calc(50% + 8px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
}

.timeline-block-right {
    float: right;
}

.timeline-block-left {
    float: left;
    direction: rtl;
    
}

.marker {
    @media screen and (max-width: 767px) {
        background-image: url('../../images/verticalGlow.png');
        width: 10px;
        height: 175px;
        position: relative;
        left: 3px;
        top: 15px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    @media screen and (min-width: 768px) {
        background-image: url('../../images/verticalGlow.png');
        width: 10px;
        height: 175px;
        position: relative;
        left: -3px;
        top: 15px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

.marker_right {
    // width: 16px;
    // height: 16px;
    // border-radius: 50%;
    // border: 2px solid #e5bf00;
    // background: #e5bf00;
    // margin-top: 20%;
    // z-index: 9999;
    background-image: url('../../images/verticalGlow.png');
    width: 10px;
    height: 175px;
    position: relative;
    left: 3px;
    top: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.timeline-content {
    width: 95%;
    // padding: 0 15px;
    color: #666;
    .content_card {
        direction: ltr;
    }
}
.content_card {
    position: relative;
    .top-left-angle {
        position: absolute;
    top: -3px;
    left: -3px;
    border-top: 16px solid black;
    border-right: 24px solid transparent;
    border-bottom: 24px solid transparent;
    border-left: 16px solid black;

        &::after {
            position: absolute;
            transition: all 0.5s;
            content: "";
            height: 50px;
            border-left: 1px solid gold;
            border-right: 1px solid transparent;
            border-bottom: 1px solid transparent;
            border-top: 1px solid gold;
            transform: rotate(45deg);
            top: -20px;
            left: 3px;
        }       
    }
    .bottom-left-angle {
        position: absolute;
        bottom: -2px;
        right: -2px;
        border-top: 32px solid transparent ;
        border-right: 20px solid black;
        border-bottom: 7px solid black;
        border-left: 21px solid transparent;

        &::after {
            position: absolute;
            transition: all 0.5s;
            content: "";
            height: 50px;
            border-left: 1px solid transparent;
            border-right: 1px solid gold;
            border-bottom: 1px solid gold;
            border-top: 1px solid transparent;
            transform: rotate(45deg);
            bottom: -12px;
        }

        
    }
}
.content_card:hover{
    .top-left-angle{
        top: -4px;
        left: -4px;
    }
    &::after {
        position: absolute;
        transition: all 0.5s;
        content: "";
        height: 52px;
        border-left: 4px solid gold;
        // border-right: 2px solid transparent;
        // border-bottom: 2px solid transparent;
        border-top: 4px solid gold;
        transform: rotate(45deg);
        top: -10px;
        left: 15px;
    }
    .bottom-left-angle {
        bottom: -4px;
        right: -4px;
        border-top: 30px solid transparent ;
        border-right: 22px solid black;
        border-bottom: 8px solid black;
        border-left: 18px solid transparent;

        &::after {
            position: absolute;
            transition: all 0.5s;
            content: "";
            height: 52px;
            // border-left: 4px solid transparent;
            border-right: 4px solid gold;
            border-bottom: 4px solid gold;
            // border-top: 4px solid transparent;
            transform: rotate(45deg);
            bottom: -14px;
        }

        
    }
}
//  .timeline-content h3 {
//     margin-top: 5px;
//     margin-bottom: 5px;
//     font-size: 25px;
//     font-weight: 500
//  }

//  .timeline-content span {
//     font-size: 15px;
//     color: #a4a4a4;
//  }

//  .timeline-content p {
//     font-size: 14px;
//     line-height: 1.5em;
//     word-spacing: 1px;
//     color: #888;
//  }

@media screen and (max-width: 767px) {
    .container_rd:before {
        left: 8px;
        width: 2px;
    }
    .timeline-block {
        width: 100%;
        margin-bottom: 30px;
    }
    .timeline-block-right {
        float: none;
    }

    .timeline-block-left {
        float: none;
        direction: ltr;
    }
}
