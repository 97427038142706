// POPPINS
@font-face {
  font-family: Helvetica;
  font-weight: 300;
  src: url("../fonts/helvetica/helvetica-light.ttf");
}
@font-face {
  font-family: Helvetica;
  font-weight: 400;
  src: url("../fonts/helvetica/Helvetica.ttf");
}
@font-face {
  font-family: Helvetica;
  font-weight: 500;
  src: url("../fonts/helvetica/Helvetica.ttf");
}
@font-face {
  font-family: Helvetica;
  font-weight: 600;
  src: url("../fonts/helvetica/Helvetica.ttf");
}
@font-face {
  font-family: Helvetica;
  font-weight: 700;
  src: url("../fonts/helvetica/Helvetica-Bold.ttf");
}
@font-face {
  font-family: Strasua;
  src: url("../fonts/Strasua/Strasua-Regular.ttf");
}
:root {
  --font-display: Helvetica;
  --font-body: Helvetica;
  --font-strasua: Strasua;
}
//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-strasua;
}

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}
