.buyNFT2 {
    border: 1px solid #E5BF00;
    background-color: rgb(255 202 101 / 10%);
    padding: 1.9em;
}

.buyNFT2:hover {
    background-color: black;
}

.content_card_buyNft {
    // position: relative;

    .top-left-angle {
        position: absolute;
        top: -4px;
        left: -4px;
        border-top: 24px solid black;
        border-right: 24px solid transparent;
        border-bottom: 24px solid transparent;
        border-left: 24px solid black;

        &::after {
            position: absolute;
            transition: all 0.5s;
            content: "";
            height: 62px;
            border-left: 1px solid gold;
            border-right: 1px solid transparent;
            border-bottom: 1px solid transparent;
            border-top: 1px solid gold;
            transform: rotate(45deg);
            top: -30px;
            left: 0px;
        }
    }
}

%test {
    position: absolute;
    content: "";
    transition: all 0.5s;
}


.content_card_buyNft_div {
    // display: inline-block;
    // padding: 20px 40px;
    color: white;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    transform: translate(-0.1%, -0.1%);
    vertical-align: middle;
    // font-family: "Strasua";
    text-decoration: none;
    //   font-size: 4vw;
    transition: all 0.5s;
    //   background-color: #3498db;
    border: 1px solid gold;

    &::before {
        @extend %test;
        // bottom: -15px;
        // height: 15px;
        // width: 100%;
        // left: 8px;
        // transform: skewX(45deg);
        // border: 1px solid gold;

        bottom: -9px;
        height: 9px;
        width: 100.5%;
        left: 4px;
        transform: skewX(50deg);
        border: 1px solid gold;
        border-top: 0px;
        border-right: 0px;
    }

    &::after {
        @extend %test;
        /*  right: -10px;
        height: 100%;
        width: 10px;
        bottom: -8px;
        transform: skewY(45deg);
        border: 1px solid gold; */
        right: -12px;
        height: 100%;
        width: 13px;
        bottom: -4px;
        transform: skewY(38deg);
        border: 1px solid gold;
        border-left: 0px;
    }
    &:hover::after,
    &:hover::before {
        background-color: gold;
    }
    
}

%faqafter{
    position: absolute;
    top: 0;
    left: 0;
    font-size: 90px;
    line-height: 67px;
    opacity: 33%;
    z-index: -1;
    display: none;
    -webkit-text-stroke: 2px #E5BF00;
    font-family: "Strasua";
    -webkit-text-fill-color: transparent;
}

%faqhover{
    top: -80px;
    display: block;
    transition: 0.75s all ease;
    z-index: 1;
}


#faq_div_1 {
    position: relative;
    &::after {
        content: "01";
        @extend %faqafter;
    }

    &:hover::after {
        @extend %faqhover;
    }
}
#faq_div_2 {
    position: relative;
    &::after {
        content: "02";
        @extend %faqafter;
    }

    &:hover::after {
        @extend %faqhover;
    }
}#faq_div_3 {
    position: relative;
    &::after {
        content: "03";
        @extend %faqafter;
    }

    &:hover::after {
        @extend %faqhover;
    }
}