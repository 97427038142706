.content_card_faq {
  position: relative;

  .top-left-angle {
    position: absolute;
    top: -3px;
    left: -3px;
    border-top: 18px solid black;
    border-right: 16px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 10px solid black;

    &::after {
      position: absolute;
      transition: all 0.5s;
      content: "";
      height: 31px;
      border-left: 1px solid gold;
      border-right: 1px solid transparent;
      border-bottom: 1px solid transparent;
      border-top: 1px solid gold;
      transform: rotate(45deg);
      top: -20px;
      left: 3px;
    }
  }
}

%test {
  position: absolute;
  content: "";
  transition: all 0.5s;
}
.content_card_faq_div {
  // display: inline-block;
  // padding: 20px 40px;
  color: white;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  transform: translate(-0.1%, -0.1%);
  vertical-align: middle;
  // font-family: "Strasua";
  text-decoration: none;
  //   font-size: 4vw;
  transition: all 0.5s;
  //   background-color: #3498db;
  border: 1px solid gold;
  &::before {
      @extend %test;
      // bottom: -15px;
      // height: 15px;
      // width: 100%;
      // left: 8px;
      // transform: skewX(45deg);
      // border: 1px solid gold;

      bottom: -9px;
      height: 9px;
      width: 100.5%;
      left: 4px;
      transform: skewX(50deg);
      border: 1px solid gold;
      border-top: 0px;
      border-right: 0px;
  }

  &::after {
      @extend %test;
     /*  right: -10px;
      height: 100%;
      width: 10px;
      bottom: -8px;
      transform: skewY(45deg);
      border: 1px solid gold; */
      right: -12px;
  height: 100%;
  width: 13px;
  bottom: -4px;
  transform: skewY(38deg);
  border: 1px solid gold;
  border-left: 0px;
  }

}