.nc-fill-container {
    min-height: calc(100vh - 153px);
}

// .tc-container{
//     width: 100%;
//     margin-right: auto;
//     margin-left: auto;
//     padding-right: 1rem;
//     padding-left: 1rem;
// }

// @media (min-width: 640px){
//     .container {
//         max-width: 640;
//     }
// }
// @media (min-width: 768px){

//     .container {
//         max-width: 768px;
//     }
// }
// @media (min-width: 1024px){

//     .container {
//         max-width: 1024px;
//     }
// }
// @media (min-width: 1280px){

//     .container {
//         max-width: 1280px;
//         padding-right: 40px;
//         padding-left: 40px;
//     }
// }
// @media (min-width: 1536px){
//     .container {
//         max-width: 1536px;
//         padding-right: 128px;
//         padding-left: 128px;
//     }
// }

// @media (min-width: 1536px){
//     .container {
//         max-width: 1536px;
//         padding-right: 128px;
//         padding-left: 128px;
//     }
// }

element.style {
}
.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.justify-between {
    justify-content: space-between;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.relative {
    position: relative;
}


.bg-primary-color {
    background-color: rgb(215, 152, 254);
}

.text-primary-color {
    color: rgb(215, 152, 254);
}

.border-radius-50 {
    border-radius: 50%;
}

.nc-CardCategory5 {
    margin: 0 10px;
}

.hide-scroll {
    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }

    /* For IE, Edge and Firefox */
    .scrollbar-hide {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

.swal-modal {
    background-color: rgb(43 43 43);
}
.swal-button {
    background-color: rgba(229, 191, 0);
    border: none;
    color: rgb(5, 5, 5);
}
.swal-button:hover {
    background-color: rgba(229, 191, 0) !important;
}
.swal-title,
.swal-text {
    color: #fff;
}

%test {
    position: absolute;
    content: "";
    transition: all 0.5s;
}

.button-primary {
    display: inline-block;
    padding: 20px 40px;
    color: white;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    transform: translate(-0.1%, -0.1%);
    vertical-align: middle;
    font-family: "Strasua";
    text-decoration: none;
    //   font-size: 4vw;
    transition: all 0.5s;
    //   background-color: #3498db;
    border: 1px solid gold;
    &::before {
        @extend %test;
        // bottom: -15px;
        // height: 15px;
        // width: 100%;
        // left: 8px;
        // transform: skewX(45deg);
        // border: 1px solid gold;

        bottom: -7px;
        height: 7px;
        width: 101%;
        left: 3px;
        transform: skewX(50deg);
        border: 1px solid gold;
        border-top: 0px;
    }

    &::after {
        @extend %test;
       /*  right: -10px;
        height: 100%;
        width: 10px;
        bottom: -8px;
        transform: skewY(45deg);
        border: 1px solid gold; */
        right: -9px;
    height: 103%;
    width: 9px;
    bottom: -4px;
    transform: skewY(38deg);
    border: 1px solid gold;
    border-left: 0px;
    border-bottom: 0px;
    }

    &:hover::after,
    &:hover::before {
        background-color: gold;
    }

    &:active {
        margin-left: 0px;
        margin-top: 0px;
        &::before {
            bottom: -1px;
            height: 1px;
            left: 1px;
        }

        &::after {
            right: -1px;
            width: 1px;
            bottom: -1px;
        }
    }
}

.up-arrow {
    position: absolute;
    top: -2px;
    left: -2px;
    border-top: 10px solid black;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid black;

    &::after {
        position: absolute;
        transition: all 0.5s;
        content: "";
        height: 25px;
        border-left: 1px solid gold;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-top: 1px solid gold;
        transform: rotate(45deg);
        top: -12px;
    }
}
.hrBgImage {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(229, 255, 255, 1), rgba(229, 191, 0, 1), rgba(229, 255, 255, 1), rgba(0, 0, 0, 1));
}

.invert-button-primary {
    display: inline-block;
    padding: 20px 40px;
    color: white;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    transform: translate(-0.1%, -0.1%);
    vertical-align: middle;
    font-family: "Strasua";
    text-decoration: none;
    //   font-size: 4vw;
    transition: all 0.5s;
    //   background-color: #3498db;
    border: 1px solid black;
    &::before {
        @extend %test;
        // bottom: -15px;
        // height: 15px;
        // width: 100%;
        // left: 8px;
        // transform: skewX(45deg);
        // border: 1px solid gold;

        bottom: -7px;
        height: 7px;
        width: 101%;
        left: 3px;
        transform: skewX(50deg);
        border:1px solid black;
        border-top: 0px;
    }

    &::after {
        @extend %test;
       /*  right: -10px;
        height: 100%;
        width: 10px;
        bottom: -8px;
        transform: skewY(45deg);
        border: 1px solid gold; */
        right: -9px;
    height: 101%;
    width: 9px;
    bottom: -3px;
    transform: skewY(38deg);
    border: 1px solid black;
    border-left: 0px;
    border-bottom: 0px;
    }

    &:hover::after,
    &:hover::before {
        background-color: black;
    }

    &:active {
        margin-left: 0px;
        margin-top: 0px;
        &::before {
            bottom: -1px;
            height: 1px;
            left: 1px;
        }

        &::after {
            right: -1px;
            width: 1px;
            bottom: -1px;
        }
    }
}

.invert-up-arrow {
    position: absolute;
    top: -2px;
    left: -2px;
    border-top: 10px solid #E5BF00;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #E5BF00;

    &::after {
        position: absolute;
        transition: all 0.5s;
        content: "";
        height: 25px;
        border-left: 1px solid black;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-top: 1px solid black;
        transform: rotate(45deg);
        top: -12px;
    }
}