.content_card_our_collection {
    position: relative;
    border: 1px solid gold;

    .top-left-angle {
        position: absolute;
        top: -4px;
        left: -4px;
        border-top: 24px solid black;
        border-right: 24px solid transparent;
        border-bottom: 24px solid transparent;
        border-left: 24px solid black;

        &::after {
            position: absolute;
            transition: all 0.5s;
            content: "";
            height: 62px;
            border-left: 1px solid gold;
            border-right: 1px solid transparent;
            border-bottom: 1px solid transparent;
            border-top: 1px solid gold;
            transform: rotate(45deg);
            top: -30px;
            left: 0px;
        }
    }
    
}
.top-left-angle-1 {
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 0 15%);
}

.our-collection-mb{
    @media only screen and (min-width: 768px) and (max-width: 1279px)  {
         padding-bottom: 50px; 
    }
}