.top-left-angle {
    position: absolute;
    top: -3px;
    left: -3px;
    border-top: 18px solid black;
    border-right: 16px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 10px solid black;

    &::after {
        position: absolute;
        transition: all 0.5s;
        content: "";
        height: 31px;
        border-left: 1px solid gold;
        border-right: 1px solid transparent;
        border-bottom: 1px solid transparent;
        border-top: 1px solid gold;
        transform: rotate(45deg);
        top: -20px;
        left: 3px;
    }
}